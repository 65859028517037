import path from 'path'
import $merge from 'lodash.merge'
import $set from 'lodash.set'

const DEFAULT_CONFIG = {
  name: undefined,
  version: null,
  routes: [],
  config: null,
  configureBubblecast() {},
  nuxt() {
    return {}
  },
  styleResources: {
    scss: [],
  },
}

const getShellConfig = (shellName) => {
  const initConfig = Object.assign({}, DEFAULT_CONFIG)
  let cleanShellName = shellName

  // array format ?
  if (Array.isArray(shellName)) {
    cleanShellName = shellName[0]
    $merge(initConfig, shellName[1])
  }

  const shellPath = `../../excentrics/shells/${cleanShellName}`
  const baseConfig = require(shellPath + '/config.js')

  return $merge({}, initConfig, baseConfig, {
    shellName: cleanShellName,
    shellPath,
  })
}

class BubbleShell {
  constructor() {
    this.loaded = false
  }

  load(shellName) {
    if (shellName) {
      try {
        const initConfig = {}
        let cleanShellName = shellName

        if (Array.isArray(shellName)) {
          cleanShellName = shellName[0]
          $merge(initConfig, shellName[1])
        }

        const shellPlugin = require(`../../excentrics/shells/${cleanShellName}/config.js`)

        this.config = $merge({}, shellPlugin.config, initConfig)
        this.loaded = true
      } catch (error) {
        console.error(error)
      }
    }

    return this
  }
}

export const configureshell = (context, config) => {
  if (!config.useShell) {
    return // noop
  }

  try {
    const shellConfig = getShellConfig(config.useShell)

    if (!shellConfig.name) {
      console.warn('[bubble shell warning] Missing shell name in config file')
    }
    if (!shellConfig.version) {
      console.warn(
        '[bubble shell warning] Missing shell version in config file'
      )
    }
    if (!shellConfig.routes) {
      console.warn('[bubble shell warning] No routes defined')
    }

    if (typeof shellConfig.nuxt === 'function') {
      $merge(context, shellConfig.nuxt(context))
    } else if (typeof shellConfig.nuxt === 'object') {
      $merge(context, shellConfig.nuxt)
    }

    if (shellConfig.styleResources) {
      $merge(context.styleResources, shellConfig.styleResources)
    }

    // we set dynamically the router.extendRoutes function
    // which override default pages with current shell pages
    $set(context.options, 'router.extendRoutes', (routes) => {
      const r = routes.map((route) => {
        if (shellConfig.routes.includes(route.name)) {
          return {
            ...route,
            component: path.resolve(
              __dirname,
              '../../excentrics/shells/',
              shellConfig.shellName,
              `${route.name}.vue`
            ),
          }
        }

        return route
      })

      if (shellConfig.additionalRoutes) {
        shellConfig.additionalRoutes.forEach((route) => {
          // todo: check integrity
          r.push(route)
        })
      }

      return r
    })
  } catch (error) {
    console.error(error)
  }
}

export const bubbleshell = new BubbleShell()
