import $merge from 'lodash.merge'

import Excentrics from '../excentrics'
import { $data } from './spoke'

import { bubbleshell } from './voicerconfig/shells'
import VoicerConfigPage from './voicerconfig/Pages'

let _config = require(process.env.CONFIG_FILE)

export const config = _config

let _context = null
let _routes = null

export const getContext = () => _context

export const setContext = (context) => {
  _context = context
}

if (
  process.env.NODE_ENV === 'production' &&
  config.authForceLocaleForDevelopment === true
) {
  console.warn(`authForceLocaleForDevelopment is true in production mode`)
}

export const displayRGPD = () => {
  return getConfig('displayRGPDModal', false)
}

/**
 * @type {Function(default: string)<Function(route: object, store: object)>}
 * the current page title
 * common on every pages (if you set a global headTitle of type string)
 * or configurable via a function (see example below)
 *
 * @todo i18n
 */
export const headTitle = (defaultTitle) => {
  return function (route, state) {
    switch (route.name) {
      case 'login':
        return ['Join', '-', defaultTitle].join(' ')
      case 'listen-content':
        if (state.player.isLoading) {
          return ['...', '-', defaultTitle].join(' ')
        } else {
          return [state.player.content.name, '-', defaultTitle].join(' ')
        }
    }

    return defaultTitle
  }
}

/**
 * @type {Function(default: string)<Function(route: object, store: object)>}
 * the current page description (meta name="description")
 * common on every pages (if you set a string)
 * or the result of the passed function (as `headTitle` above)
 * @example
 * // see headTitle
 */
export const headDescription = (defaultDescription) => {
  return function (route, store) {
    return defaultDescription || `Tous vos podcasts privés au même endroit`
  }
}

export const configurePages = (contextFn) => {
  const voicerPage = new VoicerConfigPage()
  const configuredPages = contextFn(voicerPage)
  const usrDefinedConfigPager = getConfigStrict(
    '__usrDefinedConfig.pages',
    (_) => _
  )

  return usrDefinedConfigPager(configuredPages).toArray()
}

export const findPageFromSlug = (
  slug,
  path = null,
  defaultValue = undefined
) => {
  const route = getRoutes().find((page) => {
    return page.slug === slug
  })

  return !path ? route : $data(route, path, defaultValue)
}

export const getRoutes = () => {
  if (_routes) {
    return _routes
  }

  _routes = configurePages(getConfigStrict('pages', (_) => _))

  return _routes
}

export const getConfig = (prop, defaultValue) => {
  const value = $data(config, prop, defaultValue)

  if (typeof value === 'function') {
    return value.call(_context, config)
  }

  return value
}

export const getConfigStrict = (prop, defaultValue) => {
  return $data(config, prop, defaultValue)
}

export const getCardsImagesGradients = (context) => {
  return getThemeConfigProperty(context)('imageGradient')
}

export const getShellConfig = (prop, defaultValue) => {
  return getConfig(`bubbleshell.${prop}`, defaultValue)
}

export const getShellConfigStrict = (prop, defaultValue) => {
  return getConfigStrict(`bubbleshell.${prop}`, defaultValue)
}

export const getThemeConfig = (_) => $data(config, 'theme')

export const getThemeConfigProperty = (context) => (prop, defaultValue) => {
  const configProp = $data(getThemeConfig(), prop, defaultValue)

  if (typeof configProp === 'function') {
    return configProp(context)
  }

  return configProp
}

export const hasDisabledComponent = (name) => {
  const components = getConfig('disabledComponents', [])

  return components.includes(name)
}

export const hasSSOProvider = (method) => {
  const auths = getSSOProviders()

  return Array.isArray(auths) ? auths.some(([name]) => name === method) : false
}

export const useLocalAuth = () => {
  const mode = getConfig('authMode')

  if (getConfig('authForceLocaleForDevelopment', false)) {
    return true
  }

  if (mode.includes('local')) {
    return true
  } else if (Array.isArray(mode)) {
    return !!mode.find((_m) => {
      return Array.isArray(_m) && _m[0] === 'local'
    })
  }

  return false
}

export const useProvidersAuth = () => {
  return getConfig('authMode').includes('providers')
}

export const getLocalAuthOptions = () => {
  const DEFAULT_OPTIONS = {
    mode: 'default',
  }

  const modeLocal = getConfig('authMode').find(
    (_m) => Array.isArray(_m) && _m[0] === 'local'
  ) || ['local', DEFAULT_OPTIONS]

  const options = $merge({}, DEFAULT_OPTIONS, modeLocal[1])

  if (options.mode === 'unique_password' && !options.defaultLogin) {
    throw new Error(`missing defaultLogin property on authMode local option`)
  }

  return options
}

export const hasLocalAuthModeUniquePassword = () => {
  const options = getLocalAuthOptions()

  return options && options.mode === 'unique_password'
}

export const getExcentricComponent = (filepath, fallback) => {
  const excCompCtx = require.context(
    '../excentrics/components',
    true,
    /^\.\/.*\.vue$/
  )
  const excCompFiles = excCompCtx.keys().map((moduleId) => {
    return moduleId.replace(/\.\//, '').replace('.vue', '')
  })

  const hasExcentricComponent = (filepath) => {
    return (
      excCompFiles.includes(filepath) ||
      excCompFiles.includes(filepath + '/index')
    )
  }

  if (hasExcentricComponent(filepath)) {
    return import('../excentrics/components/' + filepath)
  }

  if (typeof fallback === 'function') {
    return fallback()
  }

  if (process.env.NODE_ENV !== 'production') {
    console.warn(`Explicit component warning - ${filepath} doesn't exists`)
  }

  return {
    template: '<div></div>',
  }
}

export const getExcentricsFile = (type, reducer) => {
  if (type !== 'store') {
    throw new Error(`Bubblecast currently support only store excentrics files`)
  }

  if (config.bubbleshell && config.bubbleshell && config.bubbleshell.store) {
    if (typeof config.bubbleshell.store !== 'function') {
      throw new TypeError(
        `The config.store must be a function which returns an Object of store reducers`
      )
    }
    const store = config.bubbleshell.store(config)

    if (store) {
      const keyStore = store[reducer]

      if (keyStore) {
        return $merge({}, Excentrics.store[reducer], keyStore)
      }
    }
  }

  return Excentrics.store[reducer]
}

export const getExcentricMiddlewares = (excentrics, name, scope = 'before') => {
  return $data(excentrics.middlewares, `${name}.${scope}`, [])
}

export const getSSOProvider = (method) => {
  return getSSOProviders().find(([name]) => name === method)
}

export const getSSOProviderOptions = (method) => {
  const provider = getSSOProvider(method)

  return provider ? (Array.isArray(provider) ? provider[1] : {}) : {}
}

export const getSSOProviders = () => {
  return getConfig('authOAuthProviders', [])
}

export const isPublicRoute = (route) => {
  return !!isRouteDefinedInKey(route, 'publicRoutes')
}

export const isRouteDefinedInKey = (route, key) => {
  let routeName = route
  const croutes = getConfig(`routes.${key}`, [])

  if (!route) {
    return false
  }

  if (typeof route === 'object') {
    routeName = route.name
  }

  return croutes.find((croute) => {
    if (typeof croute === 'string') {
      return croute === routeName
    } else if (croute instanceof RegExp) {
      return croute.test(routeName)
    }
  })
}

export const isThemeDark = () => getConfig('theme.dark', false)

export const runExcentricFile = (type, reducer) => {
  const fn = getExcentricsFile(type, reducer)

  if (!fn) {
    throw new Error(`Excentric store reducer ${reducer} doesn't exists`)
  } else if (typeof fn === 'function') {
    return fn()
  } else {
    return fn
  }
}

export const runExcentricMiddlewares = (...params) => {
  const middlewares = getExcentricMiddlewares(...params)

  return (parentThis, ...entries) => {
    return middlewares.reduce((promise, current) => {
      return promise
        .then((result) => {
          return current.call(parentThis, ...entries, result)
        })
        .catch((error) => {
          console.error(`Error during middlewares evaluation`)
          throw error
        })
    }, Promise.resolve())
  }
}

export const applyConfig = (newconfig) => {
  _config = newconfig

  return _config
}

export const muteConfig = (mutation) => {
  return $merge(config, mutation)
}

// init shell :)
bubbleshell.load(config.useShell)

muteConfig({
  bubbleshell: bubbleshell.config,
})
