import spoke, { $metadata } from './spoke'
import { getConfig } from './voicer'

const noop = (_) => _

class MixpanelHelper {
  constructor() {
    this.isConfigured = false
    this.client = {
      identify: noop,
      people: {
        set: noop,
      },
      register: noop,
      time_event: noop,
      track: noop,
    }
    this.episode = null
  }

  /**
   * @todo
   * must be moved away
   */
  sendGa(
    eventCategory = 'Commons',
    eventAction = 'undefined',
    eventLabel = 'undefined'
  ) {
    window.ga('send', {
      hitType: 'event',
      eventAction,
      eventCategory,
      eventLabel,
    })
  }

  sendGaTiming(
    timingCategory = 'Commons',
    timingVar = 'undefined',
    timingLabel = 'undefined',
    timingValue = 0
  ) {
    window.ga('send', {
      hitType: 'timing',
      timingCategory,
      timingVar,
      timingValue,
      timingLabel,
    })
  }

  sendGaNul(...attrs) {
    window.ga('send', ...attrs)
  }

  sendGaSet(type, value) {
    window.ga('set', type, value)
  }

  configure(client) {
    this.client = client
  }

  identify(id, data) {
    const email = $metadata(data, 'email', 'undefined')
    const created = new Date()
    // eslint-disable-next-line
    const last_login = new Date()

    const mxpersonae = {
      ...data,
      $email: email,
      $created: created,
      $last_login: last_login,
    }
    const personae = {
      ...data,
      email,
      created,
      last_login,
    }

    if (id) {
      this.client.identify(id)
      this.client.people.set(mxpersonae)

      if (getConfig('analytics.gaFeatureUid')) {
        window.ga('set', 'userId', id)
      }

      spoke.eventManager.emit('identify', {
        id,
        personae,
      })
    }
  }

  exception(exception) {
    const exc =
      exception && typeof exception.toString === 'function'
        ? exception.toString()
        : 'unknown reason'

    this.sendGaNul('exception', exception)
    spoke.eventManager.emit('exception', {
      exDescription: exc,
      exFatal: false,
    })
    this.track('exception', exc)
  }

  register(...params) {
    spoke.eventManager.emit('register', params)
    return this.client.register(...params)
  }

  timeEvent(...params) {
    return this.client.time_event(...params)
  }

  track(...params) {
    return this.client.track(...params)
  }

  download(document) {
    const documentTitle = $metadata(document, 'title', 'unknown')

    spoke.eventManager.emit('download', document)
    this.track('download', {
      id: document.id,
      documentTitle,
    })
    this.sendGa('Interactions', 'download', documentTitle)
  }

  link(link) {
    const payload = {
      id: link.id,
      linkHref: link.value,
      linkTitle: $metadata(link, 'title', 'unknown'),
    }
    spoke.eventManager.emit('click_link', payload)
    this.track('click_link', payload)
    this.sendGa('Interactions', 'click_link', link.value)
  }

  mediaPlay(episode) {
    const episodeData = episode.toJSON()

    this.episode = episode
    this.mediaPlayAt = new Date()

    const mediaTitle = $metadata(document, 'title', 'unknown')
    const payload = {
      id: episodeData.id,
      mediaDuration: $metadata(episodeData, 'duration', -1),
      mediaSource: episodeData.value,
      mediaTitle,
    }

    this.track('media_play', payload)
    spoke.eventManager.emit('media_play', payload)
    this.timeEvent('media_listen')
    this.sendGa('Interactions', 'media_play', mediaTitle)
  }

  mediaStop() {
    if (!this.episode) {
      return false
    }
    const episodeData = this.episode.toJSON()
    const durationMS = new Date().getTime() - this.mediaPlayAt.getTime()
    const duration = parseInt(durationMS / 1000)
    const mediaTitle = $metadata(episodeData, 'title', 'unknown')
    const payload = {
      id: episodeData.id,
      mediaPlayDuration: duration,
      mediaPlayDurationMS: durationMS,
      mediaDuration: $metadata(episodeData, 'duration', -1),
      mediaTitle,
    }

    this.track('media_stop', payload)
    this.track('media_listen', payload)
    spoke.eventManager.emit('media_stop', payload)
    spoke.eventManager.emit('media_listen', payload)
    this.sendGa('Interactions', 'media_stop', mediaTitle)
    this.sendGaTiming('Interactions', 'media_listen', mediaTitle, duration)
  }

  visit(payload) {
    this.track('visit', payload)
    spoke.eventManager.emit('visit', payload)
    this.sendGaNul('pageview')
    this.sendGaSet('page', payload.to)
  }
}

export default new MixpanelHelper()
