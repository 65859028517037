export const vueI18n = {"silentFallbackWarn":true,"fallbackLocale":"fr","messages":{"fr":{"account_already_exist":"Un compte existe déjà avec cette adresse<br>Identifiez-vous ou réinitialisez votre mot de passe","account_registration_wrong_domain":"Merci d'utiliser votre email professionnel","account_created":"Votre compte a bien été créé","account_validated":"Votre compte a bien été activé","account_validation_failed":"Échec d'activation du compte","check_email":"Consultez vos emails","comment_added":"Commentaire ajouté","disconnected":"Vous êtes déconnecté","failed_login":"Impossible de vous authentifier","fetch_failed":"Récupération impossible","impossible_comment_add":"Ajout impossible","invalid_information":"Informations fournies invalides","like_created":"Favoris ajouté","media_error":"Lecture impossible","media_load_error":"Lecture du contenu impossible ({code})","password_reseted":"Mot de passe réinitialisé","err_password_404":"Ce compte n'existe pas, contactez le support.","welcome_anonymous":"Bienvenue sur {appname} 🤗","welcome_identified":"Bienvenue {name} 🤗","terms_usage_policy":"Conditions d'utilisation","legal_notice":"Mentions Légales","confidentiality_policy":"Politique de données personnelles","versions":"Versions","post_users_activate__unfetchable_user":"Ce compte n'existe pas ou est déjà activé"}}}
export const vueI18nLoader = true
export const locales = ["fr"]
export const defaultLocale = 'fr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'no_prefix'
export const lazy = false
export const langDir = null
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"alwaysRedirect":false,"fallbackLocale":"","onlyOnRoot":false}
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = false
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["fr"]
export const trailingSlash = undefined
