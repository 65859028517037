import $cloneDeep from 'lodash.clonedeep'
import $merge from 'lodash.merge'

import {
  APPEND_CONTENTS,
  SET_CONTENTS,
  SET_LOADER,
  RESET_INITIAL_STATE,
} from './types'
import notifier from '~/utils/notifier'
import spoke from '~/utils/spoke'
import {
  getConfig,
  getRoutes,
  runExcentricFile,
  runExcentricMiddlewares,
} from '~/utils/voicer'

const excentrics = runExcentricFile('store', 'contents')

const DEFAULT_PAGE_LIMIT = getConfig('paginationLimit', 8)

const DEFAULT_CONTENTS_STATE = [
  // Boolean: is error
  false,

  // Boolean: is installed (basically, a request has been made)
  false,

  // Object: payload
  {
    limit: DEFAULT_PAGE_LIMIT,
  },

  // Array: contents
  [],

  // Boolean: has more results
  false,
]

function arrayEquals(a, b) {
  const cmp = (a, b) => {
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    }
    return 0
  }
  return [...a].sort(cmp).join('') === [...b].sort(cmp).join('')
}

const queryNeedsReload = (prevQuery, current) => {
  if (!prevQuery || !current) {
    return true
  }

  const assertedPreviousQuery = Object.assign(
    {
      q: undefined,
      is: undefined,
      month: undefined,
      year: undefined,
      tags: [],
    },
    prevQuery
  )

  const differentQ = assertedPreviousQuery.q !== current.q
  const differentIs = assertedPreviousQuery.is !== current.is
  const differentDate =
    assertedPreviousQuery.month !== current.month ||
    assertedPreviousQuery.year !== current.year

  const differentTags =
    arrayEquals(assertedPreviousQuery.tags, current.tags) === false

  return differentIs || differentQ || differentTags || differentDate
}

// our fcking API is certainly too strict
const cleanPayload = (payload) => {
  if (!payload) {
    return {}
  }
  if (
    !Array.isArray(payload.tags) ||
    (Array.isArray(payload.tags) && payload.tags === 0)
  ) {
    delete payload.tags
  }

  return payload
}

const INITIAL_STATE = () => {
  const userContentsSystems = {}

  getRoutes().forEach((route) => {
    userContentsSystems[route.slug] = DEFAULT_CONTENTS_STATE
  })

  return {
    /**
     * @type {Boolean}
     */
    isLoading: false,

    /**
     * @type {Object}
     * @param {Array} currentPage
     * @param {Boolean} currentPage[0] isError
     * @param {Boolean} currentPage[1] isInstalled
     * @param {Object} currentPage[2] payload
     * @param {Array} currentPage[3] items
     * @param {Boolean} currentPage[4] more (has more results)
     */
    contents: {
      default: [...DEFAULT_CONTENTS_STATE],
      search: [...DEFAULT_CONTENTS_STATE],
      filters: [...DEFAULT_CONTENTS_STATE],
      ...userContentsSystems,
    },
  }
}

export const state = () => ({
  ...$merge(INITIAL_STATE(), excentrics.merge),
})

export const actions = $merge(
  {
    /**
     * This action calls any type of podcast
     * @param {Object} route see config.base.js
     * @param {String} payload.is can be podcast, live, video or custom
     * @param {String} payload.q used for search module
     * @param {Function} payload.init
     * @param {Function} payload.reducer
     */
    async getContents(context, route) {
      const { rootState, state, getters, dispatch, commit } = context
      // Default payload for non specific call

      const notify = notifier(dispatch)

      await runExcentricMiddlewares(excentrics, 'getContents', 'before')(
        this,
        context,
        route,
        {}
      )

      let {
        query: { q = [] },
      } = this.app.context
      const {
        query: {
          offset = 0,
          limit = DEFAULT_PAGE_LIMIT,
          month,
          year,
          mode,
          is = 'podcast',
          tags = '',
        },
      } = this.app.context

      // dirty fix
      // todo: refactor search / tags store
      if (typeof q === 'string') {
        q = [q]
      }

      const { payload, init, reducer } = route || {}

      const defaultPayload = {
        offset,
        limit,
        is,
        month,
        year,
        q,
        tags,
        qoperator: '$or',
      }

      dispatch('filters/setInitialQueries', q, {
        root: true,
      })

      let currentStoredContents = state.contents[rootState.root.currentPage]

      if (!currentStoredContents) {
        currentStoredContents = DEFAULT_CONTENTS_STATE
      }

      const payloadWithFilters = $merge(
        {},
        defaultPayload,
        rootState.filters.query
      )

      payloadWithFilters.tags = tags.split(',').filter(Boolean)
      payloadWithFilters.q = payloadWithFilters.q.join(',')

      // contents was fetched from a previous request
      const certainlyUselessCall =
        mode !== 'search' &&
        currentStoredContents[1] === true &&
        queryNeedsReload(currentStoredContents[2], payloadWithFilters) === false

      if (certainlyUselessCall) {
        return
      }

      if (reducer && typeof reducer !== 'function') {
        throw new Error(`reducer should be of type function`)
      }

      if (init) {
        await init(
          {
            dispatch,
            commit,
            state,
            rootState,
            getters,
          },
          route
        )
      }

      const NOOP_REDUCER = (contents) => contents.toArray()
      const middlewareReducer = reducer || NOOP_REDUCER

      // Loading status
      commit(SET_LOADER, true)

      const pseudoQuery = spoke.collection('contents')

      const query = $merge({}, payloadWithFilters, payload)

      pseudoQuery
        .get(cleanPayload(query))
        .on('success', async (contents) => {
          commit(SET_LOADER, false)

          // only in default mode
          // we setting the first content of the stack (most recent) as player content
          // if this one is not active yet
          const isDefaultPage = rootState.root.currentPage === 'default'

          const hasNoPlayerContent = rootState.player.content.id === null
          const hasContents = contents.length > 0

          const shouldOpenPlayerPersistant =
            this.app.$voicer.getConfig('disableAutosetPlayerContent') ===
              false &&
            isDefaultPage &&
            hasNoPlayerContent &&
            hasContents

          const bypassedContents = middlewareReducer.call(this, contents, {
            state,
            rootState,
            getters,
          })

          commit(SET_CONTENTS, {
            page: rootState.root.currentPage,
            payload: {
              ...defaultPayload,
              ...payload,
            },
            contents: bypassedContents,
            more: pseudoQuery.more,
          })

          if (shouldOpenPlayerPersistant === true && bypassedContents.length) {
            dispatch('player/setPlayerContent', bypassedContents[0], {
              root: true,
            })
            dispatch('player/forceOpenPersistantPlayer', null, {
              root: true,
            })
          }

          await runExcentricMiddlewares(excentrics, 'getContents', 'success')(
            this,
            context,
            route,
            {
              isDefaultPage,
              hasContents,
              hasNoPlayerContent,
            }
          )
        })

        .on('error', async (error) => {
          notify('error', null, error)
          commit(SET_LOADER, false)
          commit(SET_CONTENTS, {
            isError: true,
            page: rootState.root.currentPage,
            payload,
            contents: [],
            more: false,
          })
          await runExcentricMiddlewares(excentrics, 'getContents', 'error')(
            this,
            context,
            route
          )
        })
    },

    async getContent(context, route) {
      const {
        params: { content: contentId },
        query: { episodeIndex },
      } = route
      await context.dispatch(
        'player/loadPlayerContent',
        {
          contentId,
          episodeIndex,
        },
        {
          root: true,
        }
      )
      context.dispatch('player/forceOpenPersistantPlayer', null, {
        root: true,
      })
    },

    getMore(context, route) {
      const { reducer } = route
      const { commit, dispatch, rootState, getters } = context

      const pseudoQuery = spoke.collection('contents')

      if (reducer && typeof reducer !== 'function') {
        throw new Error(`reducer should be of type function`)
      }

      const notify = notifier(dispatch)
      const NOOP_REDUCER = (contents) => contents.toArray()
      const middlewareReducer = reducer || NOOP_REDUCER

      return new Promise((resolve, reject) => {
        commit(SET_LOADER, true)
        pseudoQuery
          .next(getters.payload)
          .on('success', (contents) => {
            commit(APPEND_CONTENTS, {
              page: rootState.root.currentPage,
              contents: middlewareReducer.call(this, contents, {
                state,
                rootState,
                getters,
              }),
              more: pseudoQuery.more,
            })

            commit(SET_LOADER, false)
            resolve()
          })
          .on('error', (error) => {
            notify('error', null, error)
            commit(SET_LOADER, false)
            reject(error)
          })
      })
    },

    /**
     * @todo
     * document
     */
    resetSearchResult({ commit }) {
      commit(SET_CONTENTS, {
        page: 'search',
        payload: {},
        contents: [],
        more: false,
      })
    },

    async react({ commit, dispatch }, payload) {
      const notify = notifier(dispatch)
      const { content, type } = payload

      try {
        const contentModel = spoke.document(content)
        const updatedContent = await contentModel.react(type)

        notify('success', this.app.i18n.t('like_created'))
        return contentModel.$rehydrate(updatedContent)
      } catch (error) {
        notify('error', null, error)
      }
    },

    async unreact({ commit, dispatch }, payload) {
      const { content, type } = payload

      const contentModel = spoke.document(content)
      const updatedContent = await contentModel.unreact(type)

      return contentModel.$rehydrate(updatedContent)
    },

    resetState({ commit }) {
      commit(RESET_INITIAL_STATE)
    },
  },
  excentrics.actions
)

export const mutations = $merge(
  {
    [APPEND_CONTENTS](state, { contents = [], page, more = false }) {
      state.contents[page][3] = [...state.contents[page][3], ...contents]
      state.contents[page][4] = more

      state.contents = {
        ...state.contents,
      }
    },
    [SET_CONTENTS](
      state,
      {
        isError = false,
        isInstalled = true,
        page,
        payload,
        contents = [],
        more = false,
      }
    ) {
      state.contents = {
        ...state.contents,
        [page]: [isError, isInstalled, payload, contents, more],
      }
    },

    [SET_LOADER](state, value) {
      state.isLoading = value
    },

    /**
     * reset vuex contents store on initial state
     */
    [RESET_INITIAL_STATE](state) {
      Object.assign(state, INITIAL_STATE())
    },
  },
  excentrics.mutations
)

const hasContentsForPage = (state, page) => {
  return Array.isArray(state.contents[page])
}

export const getters = $merge(
  {
    payload(state, getters, rootState) {
      if (!hasContentsForPage(state, rootState.root.currentPage)) {
        return DEFAULT_CONTENTS_STATE[2]
      }
      return cleanPayload(
        $cloneDeep(state.contents[rootState.root.currentPage][2])
      )
    },

    contents(state, getters, rootState) {
      if (!hasContentsForPage(state, rootState.root.currentPage)) {
        return DEFAULT_CONTENTS_STATE[3]
      }
      return state.contents[rootState.root.currentPage][3]
    },

    hasMore(state, getters, rootState) {
      if (!hasContentsForPage(state, rootState.root.currentPage)) {
        return DEFAULT_CONTENTS_STATE[4]
      }
      return state.contents[rootState.root.currentPage][4]
    },

    isError(state, getters, rootState) {
      if (!hasContentsForPage(state, rootState.root.currentPage)) {
        return DEFAULT_CONTENTS_STATE[0]
      }
      return state.contents[rootState.root.currentPage][0]
    },

    isInstalled(state, getters, rootState) {
      if (!hasContentsForPage(state, rootState.root.currentPage)) {
        return DEFAULT_CONTENTS_STATE[1]
      }
      return state.contents[rootState.root.currentPage][1]
    },

    isLoading(state) {
      return state.isLoading
    },

    nextContents(state) {
      return (slug) => {
        return $cloneDeep(state.contents[slug] || DEFAULT_CONTENTS_STATE)
      }
    },
  },
  excentrics.getters
)
