const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({},
  config, {
  /**
   * common configuration here
   */
  authMode: ['local'],
  siteId: '5d94ae39ef64cf1a4c5b292c',
  showCardDescription: true,
  disableRouterActivelinkComputationBasedOnMode: true,
  // disabledComponents: ['wToolbarSearch'],
  useShell: [
    'bb-multiradios',
    {
      radioHeading: {
        mail: 'mailto:radio@carglass.fr?subject=Contact%20CARGLASS%20Radio',
        phone: 'tel:+33186860087',
        phoneText: `Au 01.86.86.00.87`
      }
    }
  ],
  analytics: {
    ga: 'G-HR88V5FMLJ',
    mixpanel: 'e921d87702d541b5d50593c668206c18'
  },
  metadatas: {
    name: 'CARGLASS® RADIO',
    colorPrimary: '#E31F18'
  },
  filterVisibleFilters({ page }) {
    return (filter) => {
      if (filter.slug === 'page') {
        return false;
      } else if (filter.slug === 'categories' && page === 'podcasts')
      {
        return false;
      }
      else if (filter.slug === 'type_de_podcasts' && page === 'chronicles') 
     {
        return false;
      }
      return true;
    };
  },
  pages: (pager) => {
    return pager
      .remove('radios')
      .update('filters', {
        options: {
          payload: {
            is: 'podcast'
          },
          filters: true
        }
      })
      .update('episodes', {
        options: {
          payload: {
            is: 'podcast',
            tags: ['5eda1c87df7be419f9e43477']
          },
          highlighted: false,
          filter: false
        }
      })
      .add({
        slug: 'chronicles',
        title: 'Chroniques',
        icon: 'radio_button_checked',
        visibleInMenu: true,

        options: {
          payload: {
            is: 'podcast',
            tags: ['5eda1c95df7be4f89fe43478']
          },
          highlighted: false,
          filter: true
        },
        router: {
          path: '/chronicles'
        }
      })
      .add({
        slug: 'podcasts',
        title: 'Podcasts',
        icon: 'mic',
        visibleInMenu: true,
        options: {
          payload: {
            is: 'podcast',
            tags: ['5fb54b25ede915432f734b17']
          },
          highlighted: false,
          filter: true,
        },
        router: {
          path: '/podcasts'
        }
      })
  },
  theme: {
    dark: false,
    components: {
      'toolbar-button': {
        style: (context) => (isActive, currentRouteIndex) => {
          if (currentRouteIndex === 0) {
            return {
              backgroundColor: '#FDDC0A'
            }
          }
          return {}
        }
      }
    },
    fonts: {
      families: {
        Roboto: [300, 400, 500, 700],
        Merriweather: [300, 400, 500],
      }
    }
  }
},
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
