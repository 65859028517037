const path = require('path')

const colorPrimary = '#E31F18'

module.exports = {
  name: 'bb-multiradios',
  version: '1',
  routes: ['index'],
  config: {
    featuredSectionColor: '#FDDC0A',
    streamsSectionColor: colorPrimary,
    featuredCardsColor: ['#FFE95E'],
    radioHeading: {
      mail: 'mailto:digital@mediameeting.fr',
      phone: 'tel:+33669100972',
      mailText: 'Par mail',
      phoneText: `Au 06.69.10.09.72`
    },
    store() {
      return {
        contents: {
          middlewares: {
            getContents: {
              success: [require('./middlewares/get-contents-success')]
            }
          }
        }
      }
    },
    blocks() {
      return {
        'radio-heading': require('./blocks/radio-heading.vue')
      }
    }
  },
  configureBubblecast(config) {
    config.disablePageListen = function () {
      return this.store.state.player.content.type === 'live'
    }
    config.disableAutosetPlayerContent = true
    config.enableExperimentalBlocks = true
    config.routes.store['contents/getContents'] = [] // disable autofetch content on index
    config.routes.store['filters/getCategories'].push(
      'episodes',
      'chronicles',
      'filters'
    )
    config.redirectSearchTo = '/filters'

    config.pages = (pager) => {
      return pager
        .update('default', {
          title: 'Radios',
          visibleInMenu: true,
          icon: 'fiber_manual_record',
          iconVisibleDesktop: true,
          options: {
            payload: {
              is: 'live'
            }
          }
        })
        .update('search', {
          router: {
            path: '/filters',
            mode: 'search'
          }
        })
        .remove('podcasts')
        .remove('radios')
        .add({
          slug: 'episodes',
          title: 'Emissions',
          icon: 'radio_button_checked',
          visibleInMenu: true,
          icon: 'radio_button_checked',
          options: {
            payload: {
              is: 'podcast'
            },
            highlighted: true,
            filter: true
          },
          router: {
            path: '/episodes'
          }
        })
    }
  },
  styleResources: {
    scss: [path.resolve(__dirname, './assets/main.scss')]
  },
  additionalRoutes: [{
      name: 'episodes',
      path: '/episodes',
      component: path.resolve(__dirname, './episodes.vue'),
      chunkName: 'pages/episodes'
    },
    {
      name: 'chronicles',
      path: '/chronicles',
      component: path.resolve(__dirname, './chronicles.vue'),
      chunkName: 'pages/chronicles'
    },
    {
      name: 'filters',
      path: '/filters',
      component: path.resolve(__dirname, './filters.vue'),
      chunkName: 'pages/filters'
    },
    {
      name: 'podcasts',
      path: '/podcasts',
      component: path.resolve(__dirname, '../../pages/custom-podcasts.vue'),
      chunkName: 'pages/podcasts',
    },
  ]
}
