import $merge from 'lodash.merge'
import Vue from 'vue'

import { SET_LOADER as SET_CONTENT_LOADER } from '../contents/types'
import {
  RESET_INITIAL_STATE,
  SET_CONTENT,
  SET_EPISODE_INDEX,
  SET_INSTALLED,
  SET_LOADER,
  SET_PERSISTANT_VISIBLE,
  SET_STATUS,
} from './types'
import notifier from '~/utils/notifier'
import mixpanel from '~/utils/mixpanel'
import spoke, { $data } from '~/utils/spoke'
import { runExcentricFile } from '~/utils/voicer'

const excentrics = runExcentricFile('store', 'player')

const INITIAL_STATE = () => ({
  /**
   * @type {Boolean}
   * `true` when mediaplayer handlers are registered
   *  this operation is made in `plugins/spoke.js`
   * @default false
   */
  isInstalled: false,

  /**
   * @type {Boolean}
   * `true` while the audio file loading
   * @default false
   */
  isLoading: false,

  /**
   * @type {Boolean}
   * previously `isSmall`
   * `true` if the PersistantPlayer is visible
   * @default false
   */
  isPersistantVisible: false,

  /**
   * @type {Object}
   * currently played content abstract
   */
  content: {
    id: null,
    duration: 0,
    title: '',
    captions: {
      heading: {},
      thumbnail: {},
      cards: [{}],
    },
    content: '',
    heading: '',
    publishedAt: new Date(),
    documents: [],
    episodes: [],
    links: [],
    tags: [],
  },

  /**
   * @type {Number}
   * current selected episode index
   */
  episodeIndex: 0,

  /**
   * @type {String}
   * @enum ['loading', 'ready', 'play', 'stop', 'error']
   * current player status (default is `stop`)
   * @default stop
   */
  status: 'stop',
})

export const state = () => ({
  ...$merge(INITIAL_STATE(), excentrics.state),
})

export const actions = $merge(
  {
    /**
     * install all mediaplayer handlers,
     * shall be called whenever the player is mounted
     */
    installMediaplayer({ dispatch, commit, state }) {
      if (state.isInstalled) {
        return
      }

      const notify = notifier(dispatch)

      commit(SET_INSTALLED, true)

      const isSameContent = (_content) =>
        _content && _content.id === state.content.id

      // Spoke dependency event handlers
      spoke.mediaplayer
        .on('loading', () => {
          commit(SET_STATUS, 'loading')
        })
        .on('load', () => {
          return commit(SET_STATUS, 'ready')
        })
        .on('loaderror', (error) => {
          notify(
            'error',
            this.app.i18n.t('media_load_error', {
              code: `${error || 1}`,
            }),
            error
          )
          commit(SET_STATUS, 'error')
        })
        .on('next', (newIndex) => {
          commit(SET_EPISODE_INDEX, newIndex)
        })
        .on('seek', () => {
          commit(SET_STATUS, 'loading')
        })
        .on('play', () => {
          mixpanel.mediaPlay(spoke.mediaplayer.episode)
        })
        .on('timeupdate', (data) => {
          if (spoke.mediaplayer.isPlaying) {
            commit(SET_STATUS, 'play')
          }
        })
        .on('error', (error) => {
          notify('error', this.app.i18n.t('media_error'), error)
          commit(SET_STATUS, 'error')
        })
        .on('pause', () => {
          commit(SET_STATUS, 'stop')

          mixpanel.mediaStop()
        })
        .on('stop', ({ content }) => {
          if (isSameContent(content)) {
            commit(SET_STATUS, 'stop')
            mixpanel.mediaStop()
          }
        })
    },

    /**
     * Called when we change content or launch first content
     * will redirect user to listen/content.id
     * if you don't want redirect user, use setPlayerContent
     * @param {object} content
     * @param {boolean} noRedirect
     */
    changePlayerContent(
      { commit, dispatch, getters, state, rootState },
      { content, noRedirect = false }
    ) {
      dispatch('setPlayerContent', content)
      if (
        noRedirect === false &&
        this.$voicer.getConfig('disablePageListen') === false
      ) {
        Vue.nextTick(() => {
          this.$router.push({
            path: `/listen/${content.id}`,
            query: {
              episodeIndex: 0,
            },
          })
        })
      }
    },

    /**
     * set content in player
     * @param {object} content
     */
    setPlayerContent({ commit }, content) {
      if (!state.content || state.content.id !== content.id) {
        commit(SET_CONTENT, content) // player content
      }

      if (content) {
        commit(SET_EPISODE_INDEX, 0)
      }
    },

    /**
     * Called whenever we change the episode
     * @param {Number} index index of episode
     */
    changePlayerEpisode({ commit, dispatch, getters }, index) {
      commit(SET_STATUS, 'stop')
      commit(SET_EPISODE_INDEX, index) // media playing

      if (this.$voicer.getConfig('disablePageListen', false) === false) {
        Vue.nextTick(() => {
          this.$router.push({
            path: `/listen/${spoke.mediaplayer.content.id}`,
            query: {
              episodeIndex: index,
            },
          })
        })
      }
    },

    /**
     * @param {ObjectID} contentId
     * @param {Number} episodeIndex [default=0]
     */
    loadPlayerContent(
      { commit, dispatch, state },
      { contentId, episodeIndex = 0 }
    ) {
      if (!state.content || state.content.id !== contentId) {
        commit(SET_LOADER)
        commit(`contents/${SET_CONTENT_LOADER}`, true, { root: true })

        return new Promise((resolve, reject) => {
          spoke
            .collection('contents')
            .getOne(contentId)
            .lean()
            .on('success', (content) => {
              episodeIndex = parseInt(episodeIndex, 10)

              dispatch('changePlayerContent', { content })
              commit(SET_EPISODE_INDEX, episodeIndex)
              commit(SET_LOADER, false)
              commit(`contents/${SET_CONTENT_LOADER}`, false, { root: true })
              resolve()
            })
            .on('error', (error) => {
              this.$voicer.captureException(error)
              commit(SET_LOADER, false)
              commit(`contents/${SET_CONTENT_LOADER}`, false, { root: true })
              reject(error)
            })
        })
      }
    },

    resetState({ commit }) {
      commit(RESET_INITIAL_STATE)
    },

    /**
     * close the persistant player
     */
    closePersistantPlayer({ commit }) {
      commit(SET_PERSISTANT_VISIBLE, false)
    },

    /**
     * open persistant player
     * triggered when a media file playing, is in pause or loading
     * and user is closing the listen view
     */
    openPersistantPlayer({ commit, state }) {
      if (['error', 'stop'].includes(state.status) === false) {
        commit(SET_PERSISTANT_VISIBLE, true)
      }
    },

    /**
     * force opening of persistant player
     * should be used only if the app is runned in desktop mode
     */
    forceOpenPersistantPlayer({ commit }) {
      commit(SET_PERSISTANT_VISIBLE, true)
    },

    /**
     * close the player and stop the currently playing audio
     */
    closePlayer({ commit, dispatch }) {
      commit(SET_PERSISTANT_VISIBLE, false)
      dispatch('ctrlPlayer', 'stop')
    },

    /**
     * @todo
     * document this
     */
    async ctrlPlayer(context, controller) {
      let action
      let options

      if (typeof controller === 'string') {
        action = controller
        options = {}
      } else {
        if (controller.episodeIndex == null) {
          controller.episodeIndex = spoke.mediaplayer.episodeIndex
        }
        action = controller.action
        options = {
          content: controller.content || spoke.mediaplayer.content,
          episodeIndex: controller.episodeIndex,
        }
      }
      const control = spoke.item(context.state.content)

      switch (action) {
        case 'next':
          // eslint-disable-next-line no-case-declarations
          const nextIndex = context.state.episodeIndex + 1

          if (control.$episode(nextIndex)) {
            control.next()
            await context.commit(SET_EPISODE_INDEX, nextIndex)
          }
          break
        case 'pause':
          await control.pause()
          break
        case 'play':
          control.play(options.episodeIndex)
          await context.commit(SET_EPISODE_INDEX, options.episodeIndex)
          break
        case 'prev':
          // eslint-disable-next-line no-case-declarations
          const prevIndex = context.state.episodeIndex - 1

          if (prevIndex < 0) {
            control.seek(0)

            if (control.isPlaying) {
              control.play()
            }
          } else {
            control.prev()
            await context.commit(SET_EPISODE_INDEX, prevIndex)
          }
          break
        case 'seek':
          control.seek(options.seek || 0)
          break
        case 'stop':
          control.stop()
          break
      }
    },
  },
  excentrics.actions
)

export const mutations = $merge(
  {
    /**
     * @param {boolean} status
     * true if mediaplayer handlers are installed
     */
    [SET_INSTALLED](state, status = true) {
      state.isInstalled = status
    },

    /**
     * @public
     * @param {boolean} loading
     */
    [SET_LOADER](state, loading = true) {
      state.isLoading = loading
    },

    /**
     * @param {Object} content
     * define the currently playing content
     */
    [SET_CONTENT](state, content) {
      const item = spoke.item(content)

      spoke.mediaplayer.setContent(item)
      state.content = item.lean()
    },

    /**
     * @param {Number} index episode index
     * @default 0
     * define the currently playing episode
     */
    [SET_EPISODE_INDEX](state, index = 0) {
      state.episodeIndex = index
    },

    /**
     * @param {boolean} status
     * @default true
     * set the mini player to visible or not
     */
    [SET_PERSISTANT_VISIBLE](state, status = true) {
      state.isPersistantVisible = status
    },

    /**
     * @param {string} value loading, play, stop, error
     * define the player state to `value`
     */
    [SET_STATUS](state, value) {
      if (state.status !== value) {
        state.status = value
      }
    },

    /**
     * reset vuex player store on initial state
     */
    [RESET_INITIAL_STATE](state) {
      Object.assign(state, INITIAL_STATE())
    },
  },
  excentrics.mutuations
)

export const getters = $merge(
  {
    content(state) {
      return state.content
    },

    currentEpisode(state) {
      return Object.assign({}, state.content.episodes[state.episodeIndex])
    },

    episodes(state) {
      return state.content.episodes
    },

    hasEpisodes(state) {
      return state.content.episodes.length > 1
    },

    hasNextEpisode(state) {
      return state.content.episodes[state.episodeIndex + 1]
    },

    isFirstEpisode(state) {
      return state.episodeIndex === 0
    },

    isLastEpisode(state) {
      return !state.content.episodes[state.episodeIndex + 1]
    },

    nextEpisode(state) {
      return state.content.episodes[state.episodeIndex + 1]
    },

    isLoaded(state) {
      return state.status !== 'loading'
    },

    isLoading(state) {
      return state.status === 'loading'
    },

    isPlaying(state) {
      return state.status === 'play'
    },

    isStopped(state) {
      return state.status === 'stop'
    },

    isError(state) {
      return state.status === 'error'
    },

    isPersistantVisible(state) {
      return state.isPersistantVisible
    },

    hasCommentsSystem(state) {
      return $data(state.content, 'conversations', []).some(
        (conversation) => conversation.type === 'comment'
      )
    },
    messager(state) {
      return $data(state.content, 'conversations', []).find(
        (conversation) => conversation.type === 'comment'
      )
    },
  },
  excentrics.getters
)
