/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Carglass-radio',
        clientId: 'carglass-radio-staging',
        buttonText: 'Identification Carglass',
      },
    ],
  ],
}
