import $merge from 'lodash.merge'

import {
  RESET_INITIAL_STATE,
  SET_AUTHENTICATED,
  SET_LOADER,
  SET_PASSWORD_ASKED,
  SET_USER,
} from './types'
import notifier from '~/utils/notifier'
import spoke, { $metadata, $protected } from '~/utils/spoke'
import {
  hasLocalAuthModeUniquePassword,
  runExcentricFile,
} from '~/utils/voicer'

export const namespace = true

const excentrics = runExcentricFile('store', 'auth')

const DEFAULT_STATE = () => {
  return {
    /**
     * @type {Boolean}
     */
    isAuthenticated: false,

    /**
     * @type {Boolean}
     */
    isLoading: true,

    /**
     * @type {Boolean}
     */
    hasAskedPassword: false,

    /**
     * @type {Object}
     */
    user: spoke.user.toJSON(),
  }
}

export const state = () => ({
  ...$merge(DEFAULT_STATE(), excentrics.state),
})

export const actions = $merge(
  {
    /**
     * attempt to ask a password reset for a given `login`
     * @param {String} login user login
     * @returns {Promise}
     */
    async askNewPassword({ dispatch, commit }, login) {
      const notify = notifier(dispatch)
      commit(SET_LOADER, true)

      try {
        await spoke.authentication.askNewPassword(login)
        commit(SET_PASSWORD_ASKED, true)
        notify('success', this.app.i18n.t('check_email'))
      } catch (error) {
        if (error.status === 404) {
          notify('error', this.app.i18n.t('err_password_404'))
        } else {
          notify(error)
        }
      } finally {
        commit(SET_LOADER, false)
      }
    },

    /**
     * authenticate current spoke session regardings
     * passed `payload`
     * @param {Object} payload the credential object
     * @param {String} payload.login
     * @param {String} payload.password
     * @returns {Promise}
     *
     * @todo
     * intercept spoke errors (based on `error.status`)
     * and dispatch this informations to provide theses
     * informations on the ui
     * eg: 401: bad credentials
     * eg: 404: something is wrong with this site
     * eg: 50*: something is fucking wrong with the brocoli api...
     *
     * in the case where error.status is undefined, error
     * is related to spoke or come from the client side
     * implementation, treat this error as a 500 error
     *
     * note: !status or status > 403 should submit a notice
     * on logrocket, logly or a remote debugger
     */
    async authenticate({ dispatch, commit, redirect = true }, payload) {
      const notify = notifier(dispatch)
      commit(SET_LOADER)

      try {
        await spoke.authenticate(payload)
        const user = spoke.user.lean()

        commit(SET_USER, user)
        commit(SET_AUTHENTICATED, true)

        dispatch('notifyWelcome', user)

        if (redirect === true) {
          this.$router.replace('/')
        }
      } catch (error) {
        notify('error', error, this.app.i18n.t('failed_login'))
      } finally {
        commit(SET_LOADER, false)
      }
    },

    async authenticateWithProvider(
      { dispatch, commit },
      { brocoliAPIRoute, payload, redirect = true }
    ) {
      const notify = notifier(dispatch)
      commit(SET_LOADER)

      if ((await spoke.user.session.hasValidSession()) === false) {
        await spoke.user.session.create()
      }

      try {
        const { item: user } = await spoke.http.post(brocoliAPIRoute, payload)
        spoke.doRealtimeConnection(spoke.user.session.token)

        spoke.user.$rehydrate(user)
        spoke.user.isAuthenticated = true

        commit(SET_USER, user)
        commit(SET_AUTHENTICATED, true)

        dispatch('notifyWelcome', user)
        if (redirect === true) {
          this.$router.replace('/')
        }
      } catch (error) {
        notify('error', error, this.app.i18n.t('failed_login'))

        throw error
      } finally {
        commit(SET_LOADER, false)
      }
    },

    authenticateAuth0Provider({ dispatch }, payload) {
      dispatch('authenticateWithProvider', {
        brocoliAPIRoute: 'users/auth0/authenticate',
        payload,
        redirect: false,
      })
    },

    authenticateKeycloakProvider({ dispatch }, payload) {
      dispatch('authenticateWithProvider', {
        brocoliAPIRoute: 'users/keycloak/authenticate',
        payload,
        redirect: true,
      })
    },

    notifyWelcome(context, user) {
      const { dispatch } = context
      const notify = notifier(dispatch)
      const trkey =
        hasLocalAuthModeUniquePassword() || user.isAnonymous
          ? 'welcome_anonymous'
          : 'welcome_identified'

      notify(
        'success',
        this.app.i18n.t(trkey, {
          appname: this.app.$metadatas.appName,
          name: user.metadatas.name,
        })
      )
    },

    /**
     * logout current user
     * under the hood, will revoke the current user
     * session and create a new one before redirect
     * user on the homepage
     * should reset all the store on an INITIAL STATE
     * @returns {Promise}
     */
    async logout({ dispatch, commit }) {
      const notify = notifier(dispatch)
      commit(SET_LOADER)

      const sleep = (ms) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms)
        })

      try {
        await spoke.logout()
        notify('success', this.app.i18n.t('disconnected'))
        await sleep(500)
        // fuck this !
        // uncatched error ....
        // dispatch('resetState')
        // dispatch('player/resetState', null, { root: true })
        // dispatch('comments/resetState', null, { root: true })
        // dispatch('contents/resetState', null, { root: true })
        // dispatch('filters/resetState', null, { root: true })
      } catch (error) {
        // noop
        // it's probably a network issue (or API issue 🤪)
        // spoke can't regenerate e clean session for this user
      } finally {
        commit(RESET_INITIAL_STATE)
        commit(SET_LOADER, false)
        window.location.href = '/login?from=logout'
      }
    },

    /**
     * registration process
     *
     * will attach user session on this registration
     * @todo
     * intercept spoke registration errors
     * eg: 400: bad payload
     * eg: 422: duplicate login
     * eg: 500: api error...
     *
     * @param {Object} payload registration object
     * @param {String} payload.login basically, customer email
     * @param {String} payload.password
     * @param {String} payload.username
     * @returns {Promise}
     */
    async register({ dispatch, commit }, payload) {
      const notify = notifier(dispatch)
      commit(SET_LOADER)

      try {
        const data = await spoke.register().create({
          login: payload.login,
          password: payload.password,
          metadatas: {
            name: payload.username,
            email: payload.login,
          },
        })

        commit(SET_LOADER, false)
        notify('success', this.app.i18n.t('account_created'))

        return data
      } catch (error) {
        if (error && error.status) {
          switch (error.status) {
            default:
              notify('error', null, error)
              break
            case 400:
              notify('error', this.app.i18n.t('invalid_information'))
              break
            case 403:
              notify(
                'error',
                this.app.i18n.t('account_registration_wrong_domain')
              )
              break
            case 409:
              notify('error', this.app.i18n.t('account_already_exist'))
              break
          }
        }

        commit(SET_LOADER, false)
        // error should propaged
        return Promise.reject(error)
      }
    },

    /**
     * attempt to reset an user password with informations
     * sent via Email/SMS (see payload)
     * @param {Object} payload credential object
     * @param {String} payload.code temp code (sent via SMS/email...)
     * @param {String} payload.password user new password
     * @param {String} payload.userId user ObjectID (mongo id)
     * @returns {Promise}
     *
     * @todo
     * properly intercept API errors (see status code)
     */
    async resetPassword({ dispatch, commit }, payload) {
      const notify = notifier(dispatch)
      commit(SET_LOADER)

      try {
        await spoke.authentication.resetPassword(
          payload.userId,
          payload.code,
          payload.password
        )

        this.$router.push('/login')

        notify('success', this.app.i18n.t(`password_reseted`))
      } catch (error) {
        notify('error', error)
      } finally {
        commit(SET_LOADER, false)
      }
    },

    resetState({ commit }) {
      commit(RESET_INITIAL_STATE)
    },

    async activateAccount({ dispatch, commit }, { uid, code }) {
      const notify = notifier(dispatch)
      try {
        if (!this.$spoke.registration) {
          this.$spoke.register().resurrect(uid)
        }

        await this.$spoke.registration.validateCode(code)
        notify('success', this.app.i18n.t('account_validated'))
      } catch (error) {
        notify('error', this.app.i18n.t('account_validation_failed'))
        throw error
      }
    },
  },
  excentrics.actions
)

export const mutations = $merge(
  {
    /**
     * true if user is authenticated
     */
    [SET_AUTHENTICATED](state, isAuthenticated = true) {
      state.isAuthenticated = isAuthenticated
    },

    /**
     * true if something an authentication / registration
     * process is loading (pending API response)
     */
    [SET_LOADER](state, isLoading = true) {
      state.isLoading = isLoading
    },

    /**
     * if the user has asked password and API confirmed mail was sent
     */
    [SET_PASSWORD_ASKED](state) {
      state.hasAskedPassword = true
    },

    /**
     * set leanified spoke.user object in vuex store
     */
    [SET_USER](state, user) {
      state.user = user
    },

    /**
     * reset vuex auth store on initial state
     */
    [RESET_INITIAL_STATE](state) {
      Object.assign(state, DEFAULT_STATE())
    },
  },
  excentrics.mutations
)

export const getters = $merge(
  {
    userChars(state) {
      const name = $metadata(state.user, 'name')

      const sname = name.split(' ')

      if (sname.length >= 2) {
        return sname[0].charAt(0) + sname[1].charAt(0)
      } else if (sname.length === 1) {
        return sname[0].charAt(0)
      }
      return 'A'
    },
    userId(state) {
      return state.user.id
    },
    email(state) {
      return $protected(state.user, 'email')
    },
    name(state) {
      return $metadata(state.user, 'name')
    },
    isAuthenticated(state) {
      return spoke.site.optAuthentication === false || state.isAuthenticated
    },
  },
  excentrics.getters
)
