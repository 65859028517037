import $merge from 'lodash.merge'

import { UnsupportedMethodError } from '../../utils/error'
import Model from '../../services/Model'

const DEFAULT_MODEL = {}

export default class CommonSession extends Model {
  static modelName = 'Session'
  static modelProperties = DEFAULT_MODEL
  static resource = 'sessions'

  /**
   * @api private
   * @description retrieve the current session token
   * @see token setter
   * @see token getter
   * @default null
   */
  #token = null

  constructor(data) {
    super('sessions', $merge({}, DEFAULT_MODEL, data))
  }

  get user() {
    return this.__user
  }

  get token() {
    return this.#token
  }

  set token(token) {
    this.#token = token
    this.storeToken()
  }

  delete() {
    return this.revoke()
  }

  post() {
    throw new UnsupportedMethodError('post')
  }

  clean() {
    this.token = null
    return super.clean()
  }

  hasValidSession() {
    if (this.token === null) {
      return false
    }

    return this.__http
      .get(`/me`)
      .then(() => true)
      .catch(() => false)
  }

  async create() {
    // clear the token from spoke instance
    this.clean()

    const {
      item: { session, token: createdSessionToken }
    } = await this.__http.post('/sessions')

    this.$rehydrate(session)
    this.token = createdSessionToken

    return this
  }

  async retrieveTokenFromStore() {
    try {
      const token = this.__store.get('session.token').value()

      if (token) {
        this.token = token
      }
    } catch (error) {
      // intercept this error ?
      throw error
    }
  }

  revoke() {
    return this.__http
      .post(`/sessions.revoke`)
      .then(() => {
        this.user.clean()
        this.token = null
      })
      .catch((error) => {
        this.user.clean()
        this.token = null
        throw new Error(`Unable to revoke session`)
      })
  }

  storeToken() {
    if (this.token) {
      this.__http.defaults.headers.common.Authorization = `Bearer ${this.token}`
      this.__store.set('session.token', this.token).write()

      // todo: reflexion
      // if an exception occure
      // throw UnavailableStore exception ?
    } else {
      this.__store.unset('session.token').write()
      delete this.__http.defaults.headers.common.Authorization
    }
  }
}
